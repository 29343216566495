.progress-bar {
    position: relative;
    display: inline-flex;
}

.progress-bar-value {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-label {
    font-weight: bold;
}