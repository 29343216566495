.profile {
    height: 160px;
    position: relative;
}

.profile-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 118px;
}

.profile-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-image {
    margin-right: 10px;
}

.profile-section-btn {
    display: flex;
    align-items: center;
    align-self: flex-end;
}

.profile-text p {
    margin: 0;
}

.shop-detail-profile-text {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 68px;
}

.shop-detail-profile-text p {
    font-size: 12px;
    margin: 0;
}

.shop-detail-profile-text h3 {
    margin: 0;
    white-space: nowrap;
}

.profile-text-left {
    margin-right: 15px;
}

.profile-text-right {
    margin-left: 15px;
}

.master-admin-text {
    font-weight: lighter;
}