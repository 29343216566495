.order {
    height: 160px;
    position: relative;
}

.order-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 118px;
}

.order-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.border-text {
    width: 100px;
    border-bottom: 3px solid #5850EC;
    font-weight: 500;
    padding-bottom: 6px;
}

.border-text p {
    margin-bottom: 0;
}

.order-content {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 1fr;
    margin-top: 20px;
}

.detail-banner {
    display: flex;
}


.detail-banner-inner {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 68px;
    width: 100%;
    text-align: center;
}

.banner-text h2,
.banner-text p {
    margin: 0;
}
