.button-text {
    color: black !important;
    font-weight: bold !important;
    text-transform: none !important;
}

.upload-overlap{
    position: absolute;
}
.upload-contianer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.add-user-button {
    text-align: end;
}

.next-btn {
    padding: 6px 16px;
}

.cancel-btn {
    margin: 0 10px 0 0 !important;
}

.image-upload {
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
}

.image-upload h5 {
    margin: 0
}

.image-upload p {
    margin: 0
}

.add-icon {
    width: 38.19px;
    height: 38.19px;
}

.add-icon-color {
    color: #263238;
}

.image-upload-text {
    color: #66788A;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    height: 32px;
}


.dropzone{
    width: 100%; 
    height: 100%; 
    display:flex; 
    justify-content:center; 
    align-items:center; 
    flex-direction: column
}

.custom-image-upload {
    position: relative;
    height: 100%;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    overflow: hidden;
}

.img-wrap {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.product-image,
.post-video {
    width: 100%;
    height: 100%
}

.image-cross,
.video-cross {
    position: absolute;
    top: 0;
    right: 0;
}

.video {
    position: relative;
}

.video-opacity {
    opacity: 1.0
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.progress-label {
    font-weight: bold;
}
