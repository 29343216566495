.button-text {
    color: black !important;
    font-weight: bold !important;
    text-transform: none !important;
}

.add-user-button {
    text-align: end;
}

.next-btn {
    padding: 6px 16px;
}

.cancel-btn {
    margin: 0 10px 0 0 !important;
}
.handle{
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
    flex: 1;
}

