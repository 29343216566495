@font-face {
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
    src: url('./assets/fonts/Roboto-Regular.ttf') format('ttf');
}

.paper {
    background-color: #ffffff;
    border-radius: 2px;
}

.paper-width {
    width: 100%
}

.paper-margin {
    margin-bottom: 2em;
}
