.border-text {
    width: 58px;
    border-bottom: 3px solid #5850EC;
    font-weight: 500;
    padding-bottom: 6px;
}

.border-text p {
    margin-bottom: 0;
}

.sales-content {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    margin-top: 20px;
}

.commerce-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    margin-top: 20px;
}

.payouts-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    margin-top: 20px;
}

.kits-content,
.clubs-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 1fr;
    margin-top: 20px;
} 

.detail-banner {
    display: flex;
}


.detail-banner-inner {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 68px;
    width: 100%;
    text-align: center;
}

.banner-text h2,
.banner-text p {
    margin: 0;
}
