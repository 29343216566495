.border-heading {
    border-bottom: 3px solid #5850EC;
    font-weight: 500;
    padding-bottom: 6px;
}

.heading-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.profileListItemWrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none !important;
    margin: 12px 12px 12px 0px !important;
};
