.summary{
    border: 1px solid transparent;
}
.summary:hover {
    background-color: #F5F5F5;
    border-radius: 4px;
}

.main-account-header {
    display: flex;
    margin-left: 7px;
}

.account-icon {
    margin-right: 10px;
    align-self: center;
}

.account-text {
    white-space: nowrap;
    font-size: 14px;
    color: #546E7A;
}


.listItem:hover {
    background-color: #F5F5F5;
    border: 1px solid #F5F5F5;
    border-radius: 4px;
    width: 100%;
}
