.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: flex;
}

.one {
  --i: 1;
  background: #0047ab;
}

.two {
  --i: 2;
  background: #5d3fd3;
}

.three {
  --i: 3;
  background: #663399;
}

.four {
  --i: 6;
  background: #9370db;
}

.loader div {
  margin: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  animation: waveDotEffect 2s linear infinite;
  animation-delay: calc(var(--i) * 0.1s);
}

@keyframes waveDotEffect {
  0% {
    transform: translateY(0);
    opacity: 0.5;
  }
  25% {
    transform: translateY(-80px);
    opacity: 1;
  }
  50% {
    transform: translateY(0);
  }
}
