.wrapper {
    border: 1px solid rgb(223, 225, 230);
    border-radius: 3px;
}

.add-icon-main {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
}