.button-text {
    color: black !important;
    font-weight: bold !important;
    text-transform: none !important;
}

.add-user-button {
    text-align: end;
}

.next-btn {
    padding: 6px 16px;
}

.cancel-btn {
    margin: 0 10px 0 0 !important;
}

.image-upload {
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
}

.image-upload h5 {
    margin: 0
}

.image-upload p {
    margin: 0
}

.add-icon {
    width: 38.19px;
    height: 38.19px;
}

.add-icon-color {
    color: #263238;
}

.image-upload-text {
    color: #66788A;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    height: 32px;
}


.assetEditorWrapper {
    height: calc(100vh - 490px);
    min-height: 180px;
    display: flex;
    flex-direction: column;
}

.custom-file-upload {
    position: relative;
    height: 100%;
    cursor: pointer;
}

.img-wrap {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.profile-image {
    width: 100%;
    height: 100%
}

.upload-profile-image {
    text-align: center;
    padding: 50px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-profile-image h5 {
    margin: 0;
}

div.main-upload-profile-image:hover {
    border: 1px solid #5850EC;
    cursor: pointer;
    background-color: #eceaf1; 
}

div.main-upload-profile-image:hover .add-icon {
    color: #5850EC;
}

div.main-upload-profile-image:hover .hover-browse-link {
    text-decoration: underline;
    color: #5850EC;
}





